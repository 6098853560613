import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.css';
import Seta from "../assets/img/seta-para-baixo.svg";

function LanguageSelector() {
  const { i18n } = useTranslation();
  
  // Normalize language to only consider the main language code
  const normalizeLanguage = (lng) => lng.split('-')[0];

  const [currentLanguage, setCurrentLanguage] = useState(normalizeLanguage(i18n.language) || 'pt');

  useEffect(() => {
    const lang = normalizeLanguage(i18n.language) || 'en';
    setCurrentLanguage(lang);
  }, [i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  return (
    <div className="dropdown">
      <button className="dropbtn">
        {currentLanguage === 'pt' ? 'Português' : currentLanguage === 'en' ? 'English' : 'Español'} <img src={Seta} alt="Seta para baixo" />
      </button>
      <div className="dropdown-content">
        {currentLanguage !== 'pt' && <button onClick={() => changeLanguage('pt')}>Português</button>}
        {currentLanguage !== 'en' && <button onClick={() => changeLanguage('en')}>English</button>}
        {currentLanguage !== 'es' && <button onClick={() => changeLanguage('es')}>Español</button>}
      </div>
    </div>
  );
}

export default LanguageSelector;
