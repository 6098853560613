import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Conteudo from "./components/Conteudo";
import Rodape from "./components/Rodape";
import Topo from "./components/Topo";
import Contato from "./components/Contato";
import BodyClass from "./components/BodyClass";
import EssentiaExperience from "./components/EssentiaExperience";

function App() {
  return (
    <Router>
      <div>
        <Topo />
        <BodyClass />
        <Routes>
          <Route path="/" element={<Conteudo />} />
          <Route path="/contatos" element={<Contato />} />
          <Route path="/essentiaexperience" element={<EssentiaExperience />} />
        </Routes>
        <Rodape />
      </div>
    </Router>
  );
}

export default App;
