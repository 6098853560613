import "./Rodape.css"
import Logo from "../assets/img/essentia-group.svg"

const Rodape = () => {
    return(
        <div className="rodape">
            <img src={Logo} alt="Logo Essentia Group" />
        </div>
    )
}

export default Rodape