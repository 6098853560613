import React, { useState, useEffect, useRef } from 'react';
import LanguageSelector from "./LanguageSelector";
import "./Topo.css";
import Ho from "../assets/img/ho.svg";
import Logo from "../assets/img/essentia-group.svg";
import Menu from "../assets/img/mobile/menu.svg";

const Topo = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const isMobile = window.innerWidth < 768;

    return (
        <header>
            <nav>
                {/* Renderiza o backdrop apenas em dispositivos móveis */}
                {isMobile && (
                    <div className={`backdrop ${menuOpen ? 'show' : ''}`} onClick={closeMenu}></div>
                )}
                <div className="logo-links menu-logo-lang" ref={menuRef}>
                    <button className="menu-icon" onClick={toggleMenu}>
                        <img src={Menu} alt="Icone menu" />
                    </button>
                    <a className='logo' href="/"><img src={Ho} alt="Logo Essential Nutrition" /></a>
                    {menuOpen && (
                        <button className="close-icon" onClick={closeMenu}>
                            X {/* Botão de fechar */}
                        </button>
                    )}
                    <div className={`links ${menuOpen ? 'show' : ''}`}>
                        <ul>
                            <li className='mobile'>
                                <img src={Logo} alt="Logo Essentia Group" />
                            </li>
                            <li>
                                <a target="_blank" rel="noreferrer" href="https://essentia.com.br/">Essentia Pharma</a>
                            </li>
                            <li>
                                <a target="_blank" rel="noreferrer" href="https://www.essentialnutrition.com.br/">Essential Nutrition</a>
                            </li>
                            <li>
                                <a target="_blank" rel="noreferrer" href="https://www.noorskin.com.br/">Noorskin</a>
                            </li>
                            <li>
                                <a target="_blank" rel="noreferrer" href="https://begenerous.com.br/">Be Generous</a>
                            </li>
                           
                        </ul>
                    </div>
                    <div className='mobile lang'>
                        <LanguageSelector />
                    </div>
                </div>
                <div className='desktop'>
                    <LanguageSelector />
                </div>
            </nav>
        </header >
    );
};

export default Topo;
