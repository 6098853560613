import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importa os arquivos de tradução
import en from './locales/en.json';
import pt from './locales/pt.json';
import es from './locales/es.json';

i18n
  .use(LanguageDetector) // Detecta a linguagem do navegador
  .use(initReactI18next) // Passa o i18n para o react-i18next
  .init({
    resources: {
      en: { translation: en },
      pt: { translation: pt },
      es: { translation: es },
    },
    fallbackLng: 'en', // Linguagem padrão configurada para ingles
    detection: {
      order: ['navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false, // React já faz a sanitização
    },
    whitelist: ['en', 'pt', 'es'], // Lista de idiomas permitidos
  });

export default i18n;
